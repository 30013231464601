import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Optional,
  Output,
  Self,
} from '@angular/core';
import { ControlValueAccessor, FormControl, NgControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, startWith } from 'rxjs/operators';
import { customError } from '../../utils/custom-error';
import { trackByFn } from '../../utils/track-by/track-by-base.function';
import { MyErrorStateMatcher } from '../../utils/value-accesser-error-matcher';

export interface AutocompleteOptions<T = string> {
  name?: string;
  value: T;
}

@UntilDestroy()
@Component({
  selector: 'kp-autocomplete-select',
  templateUrl: './autocomplete-select.component.html',
  styleUrls: ['./autocomplete-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AutocompleteSelectComponent<A extends Record<string, any> | string | number, T = string>
  implements ControlValueAccessor, OnInit
{
  @Output() public search = new EventEmitter<T>();

  @Input()
  public set entities(entities: A[]) {
    this._entities = entities;
    if (this.extractValue) {
      this.extractOptions();
    }
  }

  @Input() public label: string = '';
  @Input() public extractValue: ((entity: A) => T) | ((entity: A) => A) | keyof A = (entity) => entity;
  @Input() public extractName?: ((entity: A) => string) | ((entity: A) => A) | keyof A = (entity) => entity;

  public selected: T = null;
  public form = new FormControl('');
  public onChange: (value: T) => void = () => null;
  public onTouched = (): null => null;

  public trackBy = trackByFn<AutocompleteOptions<T>>('value');
  public matcher = new MyErrorStateMatcher(this.ngControl);
  public customError = customError;

  private _options$ = new BehaviorSubject<AutocompleteOptions<T>[]>([]);
  public options$ = this._options$.asObservable().pipe(
    distinctUntilChanged(),
  );

  private _entities: A[] = [];
  private _selectedValue$ = new Subject<T>();
  private selectedValue$ = this._selectedValue$.asObservable().pipe(distinctUntilChanged());
  private formValues$ = this.form.valueChanges.pipe(startWith(''), distinctUntilChanged());

  constructor(@Optional() @Self() public ngControl: NgControl, private cdr: ChangeDetectorRef) {
    if (Boolean(this.ngControl)) {
      this.ngControl.valueAccessor = this;
    }

    this.formValues$.pipe(startWith(''), debounceTime(500), untilDestroyed(this)).subscribe((name) => {
      this.search.emit(name);
    });

    combineLatest([this.formValues$, this.options$])
      .pipe(debounceTime(500), untilDestroyed(this))
      .subscribe(([formValue, options]) => {
        const selectedOption = options.find(({ name }) => name === formValue);
        if (selectedOption) {
          this._selectedValue$.next(selectedOption.value);
        }
      });

    combineLatest([this.selectedValue$, this.options$])
      .pipe(debounceTime(5), untilDestroyed(this))
      .subscribe(([_value, options]) => {
        const selectedOption = options.find(({ value }) => value === _value);
        this.onChange(_value ?? null);

        if (selectedOption) {
          this.form.setValue(selectedOption.name || selectedOption.value);
        }

        this.cdr.markForCheck();
      });
  }

  public ngOnInit(): void {
    this.extractOptions();

    this.ngControl?.statusChanges?.pipe(untilDestroyed(this)).subscribe(() => {
      this.cdr.markForCheck();
    });
  }

  public writeValue(_value: T): void {
    this._selectedValue$.next(_value);
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  public onSelect(event: any, value: T) {
    event.stopPropagation();
    this._selectedValue$.next(value);
  }

  onKeypress() {
    this._selectedValue$.next(null);
  }

  private extractOptions() {
    if (!this.extractValue) {
      throw Error('extractValue обязателен');
    }
    if (!this.extractName) {
      this.extractName = this.extractValue as any;
    }

    const _extractName =
      this.extractName instanceof Function ? this.extractName : (entity: A) => entity[this.extractName as keyof A];

    const _extractValue =
      this.extractValue instanceof Function ? this.extractValue : (entity: A) => entity[this.extractValue as keyof A];

    this._options$.next(
      this._entities.map(
        (entity: A) =>
          ({
            name: _extractName(entity),
            value: _extractValue(entity),
          } as AutocompleteOptions<T>),
      ),
    );
  }
}
